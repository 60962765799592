<template>
  <div>
    <h1 class="text-h4">Logbook</h1>
    <v-divider class="py-2"/>
    <logbook-view :loading="loading"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import LogbookView from '../components/views/LogbookView';


export default {
  name: 'Logbook',
  components: {LogbookView},
  computed: {
    ...mapState("app", ["loading"])
  }
};
</script>