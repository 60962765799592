<template>
  <logbook/>
</template>

<script>
import Logbook from "../shared/Logbook";

export default {
  props: {
    loading: {
      type: Boolean
    }
  },
  components: {Logbook},
};
</script>
