<template>
<logbook-view/>
</template>

<script>
import LogbookView from "@/components/views/LogbookView";
export default {
name: "LogbookPage",
  components: {LogbookView}
}
</script>

<style scoped>

</style>