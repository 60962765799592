import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[(_vm.loading)?_c(VSkeletonLoader,{staticClass:"background pl-1 pr-3 my-2 text-body-2 font-weight-light text-center primary",attrs:{"type":"table-thead, table-tbody, table-tfoot"}}):_vm._e(),(!_vm.loading)?_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.myCompletedSorties,"items-per-page":5},scopedSlots:_vm._u([{key:"item.date",fn:function({ item }){return [_vm._v(" "+_vm._s(item.date)+" ")]}},{key:"item.aTD",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.aTD.localTimestamp))+" "+_vm._s(item.aTD.abbreviation)+" ")]}},{key:"item.aTA",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.aTA.localTimestamp))+" "+_vm._s(item.aTA.abbreviation)+" ")]}},{key:"item.aircraft.aircraftType",fn:function({ item }){return [_vm._v(" "+_vm._s(item.aircraft.aircraftType.manufacturerCode)+" - "+_vm._s(item.aircraft.aircraftType.modelFullName)+" ("+_vm._s(item.aircraft.aircraftType.designator)+") ")]}},{key:"item.SE",fn:function({ item }){return [_c(VIcon,[_vm._v(_vm._s(item.aircraft.aircraftType.engineCount === 1 ? 'mdi-check' : ''))])]}},{key:"item.ME",fn:function({ item }){return [_c(VIcon,[_vm._v(_vm._s(item.aircraft.aircraftType.engineCount > 1 ? 'mdi-check' : ''))])]}},{key:"item.PIC",fn:function({ item }){return [_c(VList,{attrs:{"dense":""}},_vm._l((item.PIC),function(crew,index){return _c('div',{key:index},[_c(VListItem,[_vm._v(" "+_vm._s(crew.person)+" "),_c('br')])],1)}),0)]}}],null,false,1667400521)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }