<template>
  <v-container fluid>
    <v-skeleton-loader v-if="loading"
                       class="background pl-1 pr-3 my-2 text-body-2 font-weight-light text-center primary"
                       type="table-thead, table-tbody, table-tfoot">
    </v-skeleton-loader>
    <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="myCompletedSorties"
        :items-per-page="5"
        class="elevation-1"
    >
      <template v-slot:item.date="{ item }">
        {{ item.date }}
      </template>
      <!--        // Point of Departure ident-->
      <template v-slot:item.aTD="{ item }">
        {{ formatTimestamp(item.aTD.localTimestamp) }} {{ item.aTD.abbreviation }}
      </template>
      <!--        // Point of Arrival ident-->
      <template v-slot:item.aTA="{ item }">
        {{ formatTimestamp(item.aTA.localTimestamp) }} {{ item.aTA.abbreviation }}
      </template>
      <template v-slot:item.aircraft.aircraftType="{ item }">

        {{ item.aircraft.aircraftType.manufacturerCode }} - {{ item.aircraft.aircraftType.modelFullName }}
        ({{ item.aircraft.aircraftType.designator }})
      </template>
      <template v-slot:item.SE="{ item }">
        <v-icon>{{ item.aircraft.aircraftType.engineCount === 1 ? 'mdi-check' : '' }}</v-icon>
      </template>
      <template v-slot:item.ME="{ item }">
        <v-icon>{{ item.aircraft.aircraftType.engineCount > 1 ? 'mdi-check' : '' }}</v-icon>
      </template>
      <template v-slot:item.PIC="{ item }">
        <v-list dense>
          <div v-for="(crew, index) in item.PIC" :key="index">
            <v-list-item>
              {{ crew.person }}
              <br/>
            </v-list-item>
          </div>
        </v-list>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {LogbookHttp} from "@/http/LogbookHttp";
import moment from "moment";

export default {
  name: "Logbook",
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      headers: [
        {text: 'DATE (DD/MM/YYYY)', value: 'date'},
        {text: 'DEPARTURE PLACE', value: 'pointOfDepartureICAO'},
        {text: "DEPARTURE TIME", value: "aTD"},
        {text: 'ARRIVAL PLACE', value: 'pointOfArrivalICAO'},
        {text: "ARRIVAL TIME", value: "aTA"},
        {text: "MAKE,MODEL,VARIANT", value: "aircraft.aircraftType"},
        {text: "AIRCRAFT REGISTRATION", value: "aircraft.registration"},
        {text: "SE", value: "SE"},
        {text: "ME", value: "ME"},
        {text: "TOTAL TIME OF FLIGHT", value: "pilotLogbookTotalTime"},
        {text: 'NAME(S) PIC', value: 'PIC'},
        {text: 'LANDINGS (Day)', value: 'dayLandings'},
        {text: 'LANDINGS (Night)', value: 'nightLandings'},
        {text: "REMARKS", value: "remarks"},
      ],
      myCompletedSorties: []
    }
  },
  computed: {
    ...mapState("sorties", ["sorties"]),
    ...mapState("app", ["loggedInUser"]),

  },
  async mounted() {
    await this.setLoading(true)
    this.myCompletedSorties = await LogbookHttp.generateLogbookPage(this.loggedInUser._id, 1).then(result => result.data)
    await this.setLoading(false)
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("sorties", ["setSorties"]),
    formatTimestamp(timestamp) {
      return moment.unix(timestamp).format('HH:mm')
    }
  }
}

</script>

<style scoped>

</style>